exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-produkty-index-tsx": () => import("./../../../src/pages/produkty/index.tsx" /* webpackChunkName: "component---src-pages-produkty-index-tsx" */),
  "component---src-pages-produkty-masove-fallback-slug-tsx": () => import("./../../../src/pages/produkty/masove/[fallback_slug].tsx" /* webpackChunkName: "component---src-pages-produkty-masove-fallback-slug-tsx" */),
  "component---src-pages-produkty-masove-index-tsx": () => import("./../../../src/pages/produkty/masove/index.tsx" /* webpackChunkName: "component---src-pages-produkty-masove-index-tsx" */),
  "component---src-pages-produkty-mliecne-fallback-slug-tsx": () => import("./../../../src/pages/produkty/mliecne/[fallback_slug].tsx" /* webpackChunkName: "component---src-pages-produkty-mliecne-fallback-slug-tsx" */),
  "component---src-pages-produkty-mliecne-index-tsx": () => import("./../../../src/pages/produkty/mliecne/index.tsx" /* webpackChunkName: "component---src-pages-produkty-mliecne-index-tsx" */),
  "component---src-pages-produkty-ostatne-fallback-slug-tsx": () => import("./../../../src/pages/produkty/ostatne/[fallback_slug].tsx" /* webpackChunkName: "component---src-pages-produkty-ostatne-fallback-slug-tsx" */),
  "component---src-pages-produkty-ostatne-index-tsx": () => import("./../../../src/pages/produkty/ostatne/index.tsx" /* webpackChunkName: "component---src-pages-produkty-ostatne-index-tsx" */),
  "component---src-pages-produkty-ostatne-kontent-item-other-product-elements-url-slug-value-tsx": () => import("./../../../src/pages/produkty/ostatne/{kontentItemOtherProduct.elements__url_slug__value}.tsx" /* webpackChunkName: "component---src-pages-produkty-ostatne-kontent-item-other-product-elements-url-slug-value-tsx" */)
}

